@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 0 0% 3.9%;
    --card: 0 0% 100%;
    --card-foreground: 0 0% 3.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 0 0% 3.9%;
    --primary: 0 0% 9%;
    --primary-foreground: 0 0% 98%;
    --secondary: 0 0% 96.1%;
    --secondary-foreground: 0 0% 9%;
    --muted: 0 0% 96.1%;
    --muted-foreground: 0 0% 45.1%;
    --accent: 0 0% 96.1%;
    --accent-foreground: 0 0% 9%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 0 0% 98%;
    --border: 0 0% 89.8%;
    --input: 0 0% 89.8%;
    --ring: 0 0% 3.9%;
    --chart-1: 12 76% 61%;
    --chart-2: 173 58% 39%;
    --chart-3: 197 37% 24%;
    --chart-4: 43 74% 66%;
    --chart-5: 27 87% 67%;
    --radius: 0.5rem;
  }

  body {
    @apply min-h-screen bg-background text-foreground antialiased;
  }
  .dark {
    --background: 0 0% 3.9%;
    --foreground: 0 0% 98%;
    --card: 0 0% 3.9%;
    --card-foreground: 0 0% 98%;
    --popover: 0 0% 3.9%;
    --popover-foreground: 0 0% 98%;
    --primary: 0 0% 98%;
    --primary-foreground: 0 0% 9%;
    --secondary: 0 0% 14.9%;
    --secondary-foreground: 0 0% 98%;
    --muted: 0 0% 14.9%;
    --muted-foreground: 0 0% 63.9%;
    --accent: 0 0% 14.9%;
    --accent-foreground: 0 0% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 0% 98%;
    --border: 0 0% 14.9%;
    --input: 0 0% 14.9%;
    --ring: 0 0% 83.1%;
    --chart-1: 220 70% 50%;
    --chart-2: 160 60% 45%;
    --chart-3: 30 80% 55%;
    --chart-4: 280 65% 60%;
    --chart-5: 340 75% 55%;
  }
}
.single-post img {
	width: 100%;
	max-width: 100%;
	margin: 30px 0;
	border-radius: 10px;
}

.single-post p {
	margin: 20px 0;
	line-height: 40px!important;
  font-size: 18px!important;
}
.toc h2 {
	font-size: 18px!important;
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}
/* === WordPress Headings === */
.single-post h1 {
  @apply text-4xl md:text-5xl font-bold text-white dark:text-white mb-6 leading-relaxed;
}

.single-post h2  {
  @apply text-3xl md:text-4xl font-semibold text-gray-800 dark:text-gray-200 mb-5 leading-snug;
}

.single-post h3 {
  @apply text-2xl md:text-3xl font-semibold text-gray-700 dark:text-gray-300 mb-4 leading-snug;
}

.single-post h4 {
  @apply text-xl md:text-2xl font-medium text-gray-700 dark:text-gray-300 mb-4 leading-relaxed;
}

.single-post h5 {
  @apply text-lg md:text-xl font-medium text-gray-600 dark:text-gray-400 mb-3 leading-relaxed;
}

.single-post  h6 {
  @apply text-base md:text-lg font-medium text-gray-500 dark:text-gray-400 mb-2 uppercase tracking-wide;
}

/* === Paragraphs === */
.single-post p {
  @apply text-lg md:text-xl text-gray-700 dark:text-gray-300  mb-4;
}

/* === Blockquote === */
.wp-block-quote {
  @apply border-l-4 border-gray-500 dark:border-gray-400 italic text-gray-800 dark:text-gray-200 p-4 bg-gray-100 dark:bg-gray-800 rounded-lg my-6;
}

/* === Lists === */
.wp-block-list {
  @apply list-disc pl-6 text-gray-800 dark:text-gray-200 mb-4;
}

.wp-block-list ol {
  @apply list-decimal pl-6 space-y-2;
}

.wp-block-list li {
  @apply text-lg leading-relaxed;
}

/* === Code Blocks === */
.wp-block-code {
  @apply bg-gray-900 text-gray-200 p-4 rounded-md text-sm font-mono overflow-x-auto;
}

.wp-block-pre {
  @apply bg-gray-800 text-gray-200 p-4 rounded-md text-sm font-mono overflow-x-auto;
}

/* === Images === */
.wp-block-image {
  @apply w-full h-auto rounded-lg shadow-md my-6;
}

.wp-block-image img {
  @apply w-full h-auto rounded-lg;
}

/* === Tables === */
.wp-block-table {
  @apply w-full border-collapse border border-gray-300 dark:border-gray-600 text-left;
}

.wp-block-table th {
  @apply border border-gray-300 dark:border-gray-600 bg-gray-200 dark:bg-gray-700 text-gray-900 dark:text-gray-100 px-4 py-2 font-semibold;
}

.wp-block-table td {
  @apply border border-gray-300 dark:border-gray-600 px-4 py-2 text-gray-800 dark:text-gray-200;
}

/* === Buttons === */
.wp-block-button {
  @apply inline-block bg-blue-600 dark:bg-blue-500 text-white font-semibold py-2 px-4 rounded-lg transition hover:bg-blue-700 dark:hover:bg-blue-400;
}

/* === Separator === */
.wp-block-separator {
  @apply my-8 border-t border-gray-300 dark:border-gray-600;
}

/* === Embedded Content (Videos, iframes) === */
.wp-block-embed {
  @apply w-full my-6;
}

.wp-block-embed iframe {
  @apply w-full h-64 sm:h-80 md:h-96 rounded-lg shadow-lg;
}

/* === Galleries === */
.wp-block-gallery {
  @apply grid grid-cols-2 md:grid-cols-3 gap-4 my-6;
}

.wp-block-gallery img {
  @apply w-full h-auto rounded-lg shadow;
}

/* === Pull Quotes === */
.wp-block-pullquote {
  @apply text-xl font-semibold italic text-gray-900 dark:text-gray-100 border-l-8 border-blue-500 dark:border-blue-400 pl-6 py-4 my-6;
}

/* === Responsive Typography === */
@screen md {
  .wp-block-heading h1 {
    @apply text-5xl;
  }
  .wp-block-heading h2 {
    @apply text-4xl;
  }
  .wp-block-heading h3 {
    @apply text-3xl;
  }
  .wp-block-paragraph {
    @apply text-xl;
  }
  .wp-block-quote {
    @apply text-lg;
  }
}
